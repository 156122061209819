@import '~bootstrap';

// https://www.codeply.com/p/0CWffz76Q9

@media (max-width: 767px) {
    .carousel-multi-item .carousel-inner .carousel-item > div {
        display: none;
    }

    .carousel-multi-item .carousel-inner .carousel-item > div:first-child {
        display: block;
    }
}

.carousel-multi-item .carousel-inner .carousel-item.active,
.carousel-multi-item .carousel-inner .carousel-item-next,
.carousel-multi-item .carousel-inner .carousel-item-prev {
    display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {
    .carousel-multi-item .carousel-inner .carousel-item-end.active,
    .carousel-multi-item .carousel-inner .carousel-item-next {
        transform: translateX(25%);
    }

    .carousel-multi-item .carousel-inner .carousel-item-start.active,
    .carousel-multi-item .carousel-inner .carousel-item-prev {
        transform: translateX(-25%);
    }
}

.carousel-multi-item .carousel-inner .carousel-item-end,
.carousel-multi-item .carousel-inner .carousel-item-start {
    transform: translateX(0);
}