@import '~bootstrap';
@import '~bootstrap-icons';

// GENERAL
.text-decoration-double {
    text-decoration: underline double;
}

// PROJECT SPECIFIC
html {
    scroll-padding-top: 101px; // https://stackoverflow.com/a/69788943/20964132
}

section > .container,
section > .container-lg {
    padding: (6 * $spacer) 0;
}

#intro {
    background-image: url('../images/intro.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

#intro > div {
    background: url('../images/grid.png') repeat, rgba(0,0,0,0.55);
}

// responsive squares with icons
// credit: https://spin.atomicobject.com/2015/07/14/css-responsive-square/

#leistungen .card:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

#leistungen .card .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: (1 * $spacer);
}

#leistungen .card i {
    font-size: 3rem;
}

#preise li {
    margin: (1 * $spacer) 0;
}